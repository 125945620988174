#login__card {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;



  form {
    button {
      margin-top: -30px;
    }

    .login__card__div {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .card__fields {
      display: flex;
      justify-content: space-between;

      > div {
        width: 49%;
      }
    }
  }
  > img {
    height: 100px;
  }

  .privacypolicy {
    margin-top: 10px;

    a {
      width: 100%;
      text-align: center;
    }
  }

  .signup,
  .signin {
    display: flex;
    width: 100%;
    justify-content: center;

    > a {
      margin-left: 5px;
      text-decoration: underline;
    }
  }

  .signin {
    a {
      text-decoration: none;

      > h6 {
        color: #fff !important;
      }
    }
  }
}
