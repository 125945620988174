@-webkit-keyframes fadeIn {
  0% { opacity: 0; }
  20% { opacity: 0.15; }
  40% { opacity: 0.3; }
  60% { opacity: 0.5; }
  80% { opacity: 0.9; }
  100% { opacity: 1; }
}

@keyframes fadeIn {
  0% { opacity: 0; }
  20% { opacity: 0.15; }
  40% { opacity: 0.3; }
  60% { opacity: 0.5; }
  80% { opacity: 0.9; }
  100% { opacity: 1; }
}

.loading_icon {
  width: 70%;
  -webkit-animation: .8s linear 0s infinite alternate fadeIn;;
  animation: .8s linear 0s infinite alternate fadeIn;
}

.loading_icon_wrapper {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
