#signup {
    margin-top: auto;
    margin-bottom: auto;
    overflow: hidden;
}

@media screen and (max-width: 768px) {
    #signup {
        height: auto;
        padding-top: 0;
    }
}
