body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@font-face {
    font-family: 'Profane';
    src: url('./fonts/profane.otf');
}

@font-face {
    font-family: 'D-DIN Regular';
    font-style: normal;
    font-weight: normal;
    src: url('./fonts/D-DIN.woff') format('woff');
}

@font-face {
    font-family: 'D-DIN DIN-Italic';
    font-style: normal;
    font-weight: normal;
    src:  url('./fonts/D-DIN-Italic.woff') format('woff');
}

@font-face {
    font-family: 'D-DIN DIN-Bold';
    font-style: normal;
    font-weight: normal;
    src: local('./fonts/D-DIN DIN-Bold'), url('./fonts/D-DIN-Bold.woff') format('woff');
}

@font-face {
    font-family: 'D-DIN Condensed Regular';
    font-style: normal;
    font-weight: normal;
    src:  url('./fonts/D-DINCondensed.woff') format('woff');
}

@font-face {
    font-family: 'D-DIN Exp Regular';
    font-style: normal;
    font-weight: normal;
    src: url('./fonts/D-DINExp.woff') format('woff');
}

@font-face {
    font-family: 'D-DIN Exp DINExp-Italic';
    font-style: normal;
    font-weight: normal;
    src: url('./fonts/D-DINExp-Italic.woff') format('woff');
}

@font-face {
    font-family: 'D-DIN Condensed DINCondensed-Bold';
    font-style: normal;
    font-weight: normal;
    src: url('./fonts/D-DINCondensed-Bold.woff') format('woff');
}

@font-face {
    font-family: 'D-DIN Exp DINExp-Bold';
    font-style: normal;
    font-weight: normal;
    src: url('./fonts/D-DINExp-Bold.woff') format('woff');
}
