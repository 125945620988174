#resetpassword__form {
  button {
    margin-top: 0 !important;
  }

  .terms {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    a {
      margin-bottom: 0;

      > h6 {
        color: #fff;
        margin-top: 1px;
        margin-left: 3px;
      }
    }

    > label,
    h6 {
      margin-bottom: 0;
      font-weight: 400;
      text-transform: none;
      font-size: 12px;
    }
  }

  span {
    font-size: 13px;
    font-family: 'D-DIN Regular';
  }

}

#resetpassword {
    .signin {
        margin-top: 24px;
    }
}
