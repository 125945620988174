#background-img {
  position: fixed;
  top: 0;
  left: 0;

  > img {
    position: fixed;
    height: 100vh;
    top: 0;
  }

  > span {
    top: 0;
    position: fixed;
    display: block;
    height: 100%;
    width: 100vw;
    background: #02283b;
    opacity: 0.48;

    &.banner__bg {
      opacity: 1;
      background-color: #02283b;
    }
  }
}

@media screen and (min-width: 1280px) {
    #background-img {
        > img {
            width: 100vw;
        }
    }
}
